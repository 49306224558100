import CircleImage from './CircleImage';
import b from './images/b.png';
import m from './images/m.png';
import n from './images/n.png';

function Toolbar() {
    
    return (
        <div className='Toolbar-Image-Button-Container'>
            <CircleImage imagePath={n} title={"Could be blog"} url={"noahbrabec.com"}/>
            <CircleImage imagePath={m} title={"Could be recipes"} url={"noahbrabec.com"}/>
            <CircleImage imagePath={b} title={"Could be about me"} url={"noahbrabec.com"}/>
        </div>
    );
}

export default Toolbar;