import { useState } from 'react';


function CircleImage({imagePath, title, url}) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className='Toolbar-Image-Button'
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >

      <img className='CircleImageImage'
        src={imagePath}
        alt='Test Alt'
      />

      {isShown && (
        <a
          className="CirleImageLink"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      )}

    </div>
  );
}

export default CircleImage;