import pika from './images/pikaWorker.gif';
import warn from './images/spinnyOrangeTriangle.gif';
import './App.css';
import Toolbar from './Toolbar';

function App() {
  return (
    <div className='TopContent'>
      <Toolbar/>
      <div className='Content'>
        <div className="App">
          <header className="App-header">
            <div className='ConRow'>
              <div className='ConCol'>
                <img src={warn} className="ConstructionImage" alt="warn" />
              </div>
              <div className='ConCol'>
                <img src={pika} className="ConstructionImage" alt="pika" />
              </div>
              <div className='ConCol'>
                <img src={warn} className="ConstructionImage" alt="warn" />
              </div>
            </div>
            <h2>
              Hi,
              I'm Noah ;)
            </h2>
            <p>
              This is my website. Flex box to the max. Learning react is neato.
              <br />
              The links to the left don't work, I uh.. still need to make that stuff.
            </p>
          </header>
        </div>
      </div>
    </div>
  );
}

export default App;
